import { Button, Select, message, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useWebSocket from 'react-use-websocket';
import ShowDateTime from '../components/ShowDateTime';
import useWindowDimensions from '../components/useWindowDimensions';
import { clearBetOpen, logout, pushBetOpen, setUserData } from '../features/user/userSlice';
import { formatMoney, removeDotComma } from '../utils';
import MaintainPage from './Maintain';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import HighchartsSMA from 'highcharts/indicators/indicators';

import { t } from 'i18next';

import useSound from 'use-sound';
import winSfx from '../assets/sound/sound.mp3';
import loseSfx from '../assets/sound/error.mp3';
import clickSfx from '../assets/sound/click.mp3';
import { useNavigate } from 'react-router-dom';

const Chart = () => {
  HighchartsSMA(Highcharts);
  const { width } = useWindowDimensions();
  const { sendJsonMessage, lastJsonMessage } = useWebSocket(
    process.env.NODE_ENV === 'development' ? process.env.REACT_APP_SOCKET_URL_DEV : process.env.REACT_APP_SOCKET_URL,
  );
  const [hiddenHistoryBtn, setHiddenHistoryBtn] = useState(false);

  useEffect(() => {
    if (window.location.pathname.startsWith('/user_center')) {
      setHiddenHistoryBtn(true);
    }
  }, []);

  const navigate = useNavigate();
  const [chartData, setChartData] = useState([]);
  const [ohlcRealTime, setOhlcRealTime] = useState({});
  const [session, setSession] = useState(0);
  const [selectedAmount, setSelectedAmount] = useState('100000');
  const [selectedValue, setSelectedValue] = useState('');
  const dispatch = useDispatch();
  const { user, sys, bet } = useSelector((state) => state.user);
  const chartRef = useRef(null);
  const [playWinSound] = useSound(winSfx);
  const [playLoseSound] = useSound(loseSfx);
  const [playClickSound] = useSound(clickSfx);

  // Send user data for get ws notification
  useEffect(() => {
    const data = { username: user.username, uid: user.id };
    sendJsonMessage({ type: 'accountDetail', data });
  }, []);
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };
  // Scroll to zoom chart
  // Highcharts.Chart.prototype.callbacks.push((chart) => {
  //   Highcharts.addEvent(chart.container, 'mousewheel', (e) => {
  //     e.preventDefault();
  //     var xAxis = chart.xAxis[0];
  //     var extremes = xAxis.getExtremes();
  //     var step = ((extremes.dataMax - extremes.dataMin) / 150) * 3;
  //     var newMin = extremes.min;
  //     if (e.deltaY < 0) {
  //       newMin += step;
  //     } else {
  //       newMin -= step;
  //     }

  //     xAxis.setExtremes(newMin, extremes.max, true);
  //   });
  // });

  const formatData = (data) => {
    return [data.date, data.open, data.high - 3, data.low + 3, data.close];
  };

  const getDataDefault = (data) => {
    const dataDefault = data.map((item) => {
      return formatData(item);
    });
    setChartData(dataDefault);
  };

  const getCandleCloseTime = () => {
    return Number(ohlcRealTime.candleClose);
  };

  const BetBuySell = (betType) => {
    if (getCandleCloseTime() <= 1) {
      message.error('Vui lòng chờ đợt sau');
      return;
    }
    if (Number(removeDotComma(selectedAmount)) <= 0) {
      message.error('Vui lòng nhập số tiền hợp lệ');
      return;
    }
    if (Number(removeDotComma(selectedAmount)) > user.balance) {
      message.error('Số dư không đủ');
      return;
    }

    if (ohlcRealTime.type !== 'order') {
      message.error('Vui lòng chờ đến lúc kết thúc nến');
      return;
    }

    sendJsonMessage({
      type: 'bet',
      data: {
        username: user.username,
        uid: user.id,
        betAmount: Number(removeDotComma(selectedAmount)),
        type: betType, //buy or sell
      },
    });

    let timeGet = new Date().getTime();
    let betData = {
      ss: session,
      time: timeGet,
      amount: Number(removeDotComma(selectedAmount)),
      type: betType,
    };

    dispatch(pushBetOpen(betData));
    refreshUser();
    setSelectedValue('');
    hideModal();
  };

  const onHandleBet = () => {
    if (selectedValue === '') {
      message.error(`${t('warn1')}`);
      return;
    }
    if (Number(removeDotComma(selectedAmount)) < 10000) {
      message.error(`${t('warn2')}`);
      return;
    }

    if (getCandleCloseTime() <= 10) {
      message.error(`${t('Hết thời gian đặt cược')}`);
      return;
    }
    setOpen(true);
    //BetBuySell(selectedValue);
  };

  useEffect(() => {
    if (lastJsonMessage !== null) {
      const { type, data } = lastJsonMessage;

      if (type === 'getListDauTien') {
        if (width < 768) {
          const mobileData = data.slice(data.length - 20, data.length);
          getDataDefault(mobileData);
        } else if (width < 1024) {
          const mobileData = data.slice(data.length - 40, data.length);
          getDataDefault(mobileData);
        } else if (width < 1366) {
          const mobileData = data.slice(data.length - 70, data.length);
          getDataDefault(mobileData);
        } else {
          const mobileData = data.slice(data.length - 80, data.length);
          getDataDefault(mobileData);
        }
      }

      if (type === 'allData') {
        setOhlcRealTime(data);
        refreshUser();
      }

      if (type === 'checkBet') {
        message.success(`${t('bet_success')}`);
        playClickSound();
      }

      if (type === 'mess') {
        message.info(data.mess);

        if (data.type === 'disAccount') {
          setTimeout(() => {
            dispatch(logout());
            window.location.href = '/';
          }, 2000);
        }
      }

      if (type === 'session') {
        setSession(data);
        if (bet.length > 0) {
          if (data !== bet[0].ss) {
            dispatch(clearBetOpen());
          }
        }
        sendJsonMessage({ type: 'getListData' });
      }

      if (type === 'reloadAccount') {
        console.log('reloadAccount', data);
      }

      if (type === 'whoami') {
        dispatch(setUserData(data));
      }

      if (type === 'kq') {
        let money = data.money;
        if (data.kq === 'win') {
          message.success(`${t('you_won')} ${formatMoney(money)}`);
          playWinSound();
        } else {
          playLoseSound();
          message.error(`${t('you_lost')} ${formatMoney(money)}`);
        }
        dispatch(clearBetOpen());
      }
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    if (chartData.length > 0) {
      let lastCandle = chartData[chartData.length - 1];
      let counter = Number(ohlcRealTime.candleClose);

      let newCandleData = {
        date: ohlcRealTime.date,
        open: Number(ohlcRealTime?.open?.toFixed(2)) * 1,
        high: Number(ohlcRealTime?.high?.toFixed(2)) * 1,
        low: Number(ohlcRealTime?.low?.toFixed(2)) * 1,
        close: Number(ohlcRealTime?.close?.toFixed(2)) * 1,
      };

      if (lastCandle[0] === ohlcRealTime.date) {
        if (counter < 1) {
          setTimeout(() => {
            let chartInstance = [...chartData];
            chartInstance.shift();
            setChartData(chartInstance);
          }, 1000);
        }
        // Nến chạy từ 1 - 30s
        else {
          let chartInstance = [...chartData];
          chartInstance[chartInstance.length - 1] = formatData(newCandleData);
          setChartData(chartInstance);
        }
      }

      //  Lệch 1 nến
      else {
        let chartInstance = [...chartData];
        chartInstance.push(formatData(newCandleData));
        setChartData(chartInstance);
      }
    }
  }, [ohlcRealTime]);

  const refreshUser = () => {
    const data = { _id: user.id };
    sendJsonMessage({ type: 'whoami', data });
  };

  useEffect(() => {
    refreshUser();
  }, []);

  const options = {
    chart: {
      panning: false,
      followTouchMove: false,
      backgroundColor: 'transparent',
      height: 450,
      plotBackgroundImage: require('../assets/images/chart-bg.png'),
      animation: true,
    },
    legend: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    stockTools: {
      gui: {
        enabled: false,
      },
    },
    exporting: {
      enabled: false,
    },
    plotOptions: {
      candlestick: {
        lineColor: '#fe2e2e',
        upLineColor: '#45f248',
      },
    },
    tooltip: {
      split: false,
      enabled: true,
      label: false,
      animation: false,
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      borderWidth: 0,
      shadow: false,
      stroke: 'transparent',
      useHTML: true,
      style: {
        color: '#fff',
        fontSize: '12px',
        stroke: 'transparent',
      },
      formatter() {
        return (
          'BTC/USDT' === this.series.name &&
          '\n<span style="margin-right: 10px;"><b>O</b>: ' +
            this.point.open +
            '</span>\n<span style="margin-right: 10px;"><b>C</b>: ' +
            this.point.close +
            '</span>\n<span>&nbsp;</span>\n<br/>\n<span style="margin-right: 10px;"><b>H</b>: ' +
            this.point.high +
            '</span>\n<span style="margin-right: 10px;"><b>L</b>: ' +
            this.point.low
        );
      },
    },
    xAxis: {
      type: 'datetime',
      labels: {
        enabled: true,
        formatter() {
          return moment(this.value).format('HH:mm');
        },
        style: {
          fontSize: 10,
          color: '#707070',
        },
      },
      plotLines: [
        {
          value: 0,
          color: '#ffffff',
          width: 0.75,
          id: 'current-pricex',
          zIndex: 1e3,
          dashStyle: 'LongDash',
        },
      ],
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      minorTickLength: 0,
      tickLength: 0,
    },
    yAxis: [
      {
        opposite: true,
        gridLineColor: '#35275B',
        labels: {
          align: 'left',
          style: {
            color: '#fff',
            fontSize: '10px',
          },
        },
        title: {
          text: null,
        },
        plotLines: [
          {
            value: 0,
            color: '#ffffff',
            width: 0.75,
            id: 'current-price',
            zIndex: 100,
            label: {
              useHTML: true,
              text: 0,
              align: 'right',
              style: {
                color: '#fff',
                fontSize: '11px',
                background: 'transparent',
                borderRadius: '4px',
              },
            },
          },
        ],
        lineWidth: 0,
        resize: {
          enabled: true,
        },
      },
      {
        gridLineColor: '',
        visible: false,
        title: {
          align: 'high',
          text: null,
          rotation: 0,
          y: 0,
          useHTML: true,
        },
        height: '15%',
        lineWidth: 0,
        offset: 0,
      },
    ],
    series: [
      {
        id: 'aapl',
        type: 'candlestick',
        name: 'BTC/USDT',
        color: '#fc5f5f',
        upColor: '#31baa0',
        data: chartData,
      },
      {
        name: 'sma1',
        id: 'sma1',
        type: 'sma',
        linkedTo: 'aapl',
        color: '#2177FF',
        lineWidth: 2,
        marker: false,
        zIndex: 3,
        visible: true,
        enableMouseTracking: false,
        params: {
          index: '0',
          period: 10,
        },
        allowPointSelect: false,
        point: {
          tooltip: {
            enabled: false,
          },
        },
      },
      {
        name: 'sma2',
        id: 'sma2',
        type: 'sma',
        linkedTo: 'aapl',
        color: '#E22A67',
        lineWidth: 2,
        marker: false,
        zIndex: 3,
        visible: true,
        enableMouseTracking: false,
        params: {
          index: '1',
          period: 5,
        },
        allowPointSelect: false,
        point: {
          tooltip: {
            enabled: false,
          },
        },
      },
    ],
  };

  const RenderAmountButton = () => {
    return (
      <div className='flex flex-wrap gap-1'>
        <Button onClick={() => setSelectedAmount('20000')} className='w-20 mx-1' type='default'>
          20K
        </Button>
        <Button onClick={() => setSelectedAmount('50000')} className='w-20 mx-1' type='default'>
          50K
        </Button>
        <Button onClick={() => setSelectedAmount('100000')} className='w-20 mx-1' type='default'>
          100K
        </Button>
        <Button onClick={() => setSelectedAmount('500000')} className='w-20 mx-1' type='default'>
          500K
        </Button>
        <Button onClick={() => setSelectedAmount('1000000')} className='w-20 mx-1' type='default'>
          1M
        </Button>
        <Button onClick={() => setSelectedAmount('3000000')} className='w-20 mx-1' type='default'>
          3M
        </Button>
        <Button onClick={() => setSelectedAmount('5000000')} className='w-20 mx-1' type='default'>
          5M
        </Button>
        <Button onClick={() => setSelectedAmount('10000000')} className='w-20 mx-1' type='default'>
          10M
        </Button>
        <Button onClick={() => setSelectedAmount('20000000')} className='w-20 mx-1' type='default'>
          20M
        </Button>
        <Button onClick={() => setSelectedAmount('50000000')} className='w-20 mx-1' type='default'>
          50M
        </Button>
        <Button onClick={() => setSelectedAmount('100000000')} className='w-20 mx-1' type='default'>
          100M
        </Button>
        <Button onClick={() => setSelectedAmount('200000000')} className='w-20 mx-1' type='default'>
          200M
        </Button>
        <Button onClick={() => setSelectedAmount('300000000')} className='w-20 mx-1' type='default'>
          300M
        </Button>
        <Button onClick={() => setSelectedAmount('500000000')} className='w-20 mx-1' type='default'>
          500M
        </Button>
      </div>
    );
  };

  return (
    <div className='container-bg max-w-7xl mx-auto'>
      <div className="">
        <span className='text-black bg-white p-1 rounded-sm m-2'>BTC/USD</span>
        {sys?.bet ? <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} /> : <MaintainPage />}
      </div>
      <ShowDateTime />

      <div className='flex'>
        <div className=' flex h-10 items-center justify-between bg-[#02142b] rounded-lg w-full px-4 mb-4'>
          <span className='text-white'>
            {t('transaction')}: {session}
          </span>
          <span className='text-white'>
            {t('countdown')}:<span className='text-yellow-400'> {getCandleCloseTime()}s</span>
          </span>
        </div>
      </div>

      <div className='block md:flex'>
        <div className='flex w-full md:w-1/2'>
          <div className='w-1/2 mx-2'>
            <button
              disabled={ohlcRealTime.type === 'order' ? false : true}
              onClick={() => setSelectedValue('buy')}
              className={`${
                selectedValue === 'buy' ? 'bg-green-800' : 'bg-transparent'
              } h-12 rounded-l-xl w-full text-white border-2 md:my-0 border-green-700 ${
                ohlcRealTime.type !== 'order' && 'bg-gray-600 border-black text-gray-400 cursor-not-allowed'
              }`}
            >
              <span className='text-base font-semibold'>{t('up')}</span>
            </button>
          </div>

          <div className='w-1/2 mx-2'>
            <button
              disabled={ohlcRealTime.type === 'order' ? false : true}
              onClick={() => setSelectedValue('sell')}
              className={`${
                selectedValue === 'sell' ? 'bg-red-800' : 'bg-transparent'
              } h-12 rounded-r-xl w-full text-white border-2 md:my-0 ${
                ohlcRealTime.type !== 'order'
                  ? 'bg-gray-600 border-black text-gray-400 cursor-not-allowed'
                  : 'border-red-700'
              }`}
            >
              <span className='text-base font-semibold'>{t('down')}</span>
            </button>
          </div>
        </div>

        <div className='w-full md:w-1/2'>
          <div className='flex mt-6 md:mt-0'>
            <div className=' flex h-10 items-center justify-between bg-[#02142b] rounded-lg w-full px-4 mb-4'>
              <span className='text-white ml-2'>
                {t('balance')}: {formatMoney(user.balance)}
              </span>
              <span className='text-white mr-2'>
                {user.username}
              </span>
            </div>
          </div>
          <div className='flex w-full items-center my-2 justify-center'>
            <span className='text-white ml-1 px-4 w-24'>{t('amount_money')}</span>
            {/* <CurrencyFormat
              className=' rounded-sm h-10 px-4 w-full mx-4'
              value={selectedAmount}
              thousandSeparator={true}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                console.log(value)
                setSelectedAmount(formattedValue);
              }}
            /> */}
            <Select
              size='large'
              defaultValue='100000'
              onChange={(value) => setSelectedAmount(value)}
              className='w-9/12 md:w-11/12'
            >
              <Select.Option value='100000'>100K</Select.Option>
              <Select.Option value='200000'>200K</Select.Option>
              <Select.Option value='500000'>500K</Select.Option>
              <Select.Option value='1000000'>1M</Select.Option>
              <Select.Option value='2000000'>2M</Select.Option>
              <Select.Option value='5000000'>5M</Select.Option>
              <Select.Option value='10000000'>10M</Select.Option>
              <Select.Option value='20000000'>20M</Select.Option>
              <Select.Option value='50000000'>50M</Select.Option>
              <Select.Option value='100000000'>100M</Select.Option>
              <Select.Option value='200000000'>200M</Select.Option>
              <Select.Option value='500000000'>500M</Select.Option>
            </Select>
          </div>
          {/* <div className='flex justify-center mx-5 hidden md:block md:mx-0 md:justify-end'>
            <RenderAmountButton />
          </div> */}
      <Modal
        title="Xác nhận giao dịch ?"
        open={open}
        onOk={() => BetBuySell(selectedValue)}
        onCancel={hideModal}
        okText="Xác nhận"
        cancelText="Hủy"
        centered
      >
        Số tiền: <b>{Number(removeDotComma(selectedAmount)).toLocaleString()} đ</b> <br/>
        Cách chơi: <b>{selectedValue == 'buy' ? 'Tăng' : 'Giảm'}</b>
      </Modal>
          <div className='mx-2'>
            <button
              onClick={() => onHandleBet()}
              disabled={ohlcRealTime.type === 'order' ? false : true}
              className={`border w-full border-lightblue-400 py-2 my-2 rounded-lg active:bg-green-400 ${
                ohlcRealTime.type !== 'order' && 'cursor-not-allowed active:bg-black'
              }`}
            >
              <span className='text-white'>Xác nhận</span>
            </button>
          </div>
        </div>
      </div>
      <div
        className={`
        ${hiddenHistoryBtn ? 'hidden' : 'block'}

        justify-center items-center flex bg-slate-800 py-4 mt-10`}
      >
        <Button
          type='ghost'
          onClick={() => {
            navigate('/user_center?tab=HistoryPlay');
          }}
        >
          <span className='text-white'>Lịch sử</span>
        </Button>
      </div>
    </div>
  );
};

export default Chart;
