import { unwrapResult } from '@reduxjs/toolkit';
import { Checkbox, Divider, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getSysStatus, userRegister } from '../features/user/userSlice';
import { t } from 'i18next';
const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State
  const [terms, setTerms] = useState(true);
  const [fullname, setFullname] = useState('');
  const [phone, setPhone] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');

  // Ref query params
  const [searchParams] = useSearchParams();
  const refQuery = searchParams.get('ref');

  // Global state
  const { user, accessToken, sys } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getSysStatus());
  }, []);

  if (accessToken && user) {
    navigate('/trade');
  }

  const onHandleSubmit = async (e) => {
    e.preventDefault();

    if (!terms) {
      message.error(`${t('error_term')}`);
      return;
    }

    if (!fullname || !phone || !username || !password || !repassword) {
      message.error('Vui lòng nhập đầy đủ thông tin');
      return;
    }

    if (password !== repassword) {
      message.error(`${t('password_not_match')}`);
      return;
    }

    try {
      if (refQuery) {
        const data = await dispatch(userRegister({ fullname, phone, username, password, ref: refQuery })).then(
          unwrapResult,
        );
        if (data) {
          message.success(`${t('register_success')}`);
          navigate('/trade');
        }
      } else {
        const data = await dispatch(userRegister({ fullname, phone, username, password })).then(unwrapResult);
        if (data) {
          message.success(`${t('register_success')}`);
          navigate('/trade');
        }
      }
    } catch (error) {
      message.error(error);
    }
  };

  return (
    <div className='login_bg py-12 px-2 md:px-0'>
      <div className='flex h-full justify-center items-center'>
        <div className='card bg-[#313135] w-full md:w-[700px] pb-5 rounded-md'>
          <div>
            {/* <span>Nasdaq</span> */}
            {/* <img src={require('../assets/images/logo.png')} alt='logo' className='w-1/3 mx-auto mt-10' /> */}
            <p className='text-center text-white font-bold text-base md:text-3xl mt-6 '>{t('register')}</p>

            <div className='text-center mx-6 text-white text-base'>
              <span>{t('register1')}</span>
            </div>
          </div>

          <div className='mx-6 mt-10'>
            <div className='md:flex'>
              <div className='md:w-1/2 mx-2'>
                <span className='text-white'>{t('fullname')}</span>
                <input
                  type='text'
                  name='fullname'
                  className='w-full h-10 rounded-xl mt-2 px-2'
                  placeholder={t('fullname')}
                  onChange={(e) => setFullname(e.target.value)}
                />
              </div>

              <div className='md:w-1/2 mx-2'>
                <span className='text-white'>{t('phone')}</span>
                <input
                  type='text'
                  name='phone'
                  className='w-full h-10 rounded-xl mt-2 px-2'
                  placeholder={t('phone')}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>

            <div className='md:mt-6 mx-2'>
              <span className='text-white'>{t('username')} (viết liền không dấu)</span>
              <input
                type='text'
                name='username'
                className='w-full h-10 rounded-xl mt-2 px-2'
                placeholder={t('username')}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>

            <div className='md:flex md:mt-6'>
              <div className='md:w-1/2 mx-2'>
                <span className='text-white'>
                  {t('password')}
                  <nav></nav>
                </span>
                <input
                  name='password'
                  className='w-full h-10 rounded-xl mt-2 px-2'
                  placeholder={t('password')}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className='md:w-1/2 mx-2'>
                <span className='text-white'>{t('confirm_password')}</span>
                <input
                  name='retype_password'
                  className='w-full h-10 rounded-xl mt-2 px-2'
                  placeholder={t('confirm_password')}
                  onChange={(e) => setRepassword(e.target.value)}
                />
              </div>
            </div>

            <div className='mt-6'>
              <Checkbox defaultChecked={terms} onChange={setTerms}>
                <span className='text-white text-base'>
                  {t('register2')}
                  <Link className='text-[#ffeb3c]' to={'/policy'}>
                    {t('register3')}
                  </Link>
                </span>
              </Checkbox>
            </div>

            <div className='text-center'>
              <button
                disabled={!sys.register}
                className='mt-10 bg-[#02142b] py-2 rounded-lg px-6 text-white font-semibold'
                onClick={(e) => onHandleSubmit(e)}
              >
                {sys.register ? `${t('register')}` : 'ĐĂNG KÝ TẠM THỜI ĐÓNG'}
              </button>
            </div>

            <div className='h-1'>
              <Divider className='bg-white' />
            </div>

            <div className='flex justify-around'>
              <button className='py-2 rounded-lg px-6 text-white text-base'>{t('support')}</button>
              <Link to={'/login'}>
                <button className='py-2 rounded-lg px-6 text-white text-base'>{t('login')}</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
