import {
  BankOutlined,
  DollarOutlined,
  FileSearchOutlined,
  HistoryOutlined,
  UploadOutlined,
  UserOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Divider, Form, Image, Input, Select, Table, Upload, message } from 'antd';
import { t } from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import QRCode from 'react-qr-code';
import { useDispatch, useSelector } from 'react-redux';
import api from '../api';
import { getUserTransaction, userDeposit, userWithdraw } from '../features/transaction/transactionSlice';
import { changePassword, getSysStatus, logout, updateInfo, whoami } from '../features/user/userSlice';
import { formatMoney, removeDotComma } from '../utils';
import { TRANSACTION_STATUS, TRANSACTION_TYPE } from '../utils/constants';
import { useNavigate } from 'react-router-dom';
const Sidebar = () => {
  const dispatch = useDispatch();
  const query = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  useEffect(() => {
    if (query.get('tab') === 'HistoryPlay') {
      navigate('/user_center');
      setComponentSelected('renderLichSuChoi');
    }
  }, [query]);
  const [componentSelected, setComponentSelected] = useState('renderNapTaiSan');
  const [form] = Form.useForm();
  const [formDeposit] = Form.useForm();
  const [formWithdraw] = Form.useForm();
  const [depositAmount, setDepositAmount] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const transactionData = useSelector((state) => state.transaction.data);
  const { user, sys } = useSelector((state) => state.user);

  const [urlFront, setUrlFront] = useState('');
  const [urlBack, setUrlBack] = useState('');
  const [bankData, setBankData] = useState([]);

  const fetchUser = async () => {
    await dispatch(whoami()).then(unwrapResult);
    try {
      setUrlFront(user.id_front);
      setUrlBack(user.id_back);

      form.setFieldsValue({
        bank_name: user.bank_name,
        bank_number: user.bank_number,
        bank_holder: user.bank_holder,
        id_number: user.id_number,
        fullname: user.fullname,
        phone: user.phone,

        id_front: user.id_front,
        id_back: user.id_back,
      });
    } catch (error) {
      message.error('Lỗi khi lấy thông tin tài khoản');
      dispatch(logout());
    }
  };

  const getBankInfo = async () => {
    try {
      const { data } = await api.get('/banks');
      setBankData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUser();
    dispatch(getUserTransaction());
    dispatch(getSysStatus());
    getBankInfo();
  }, []);

  useEffect(() => {
    fetchUser();
  }, [componentSelected]);

  const renderThongTinTaiKhoan = () => {
    const onFinish = async (values) => {
      const { bank_name, bank_number, bank_holder, id_number, id_front, id_back } = values;
      try {
        await dispatch(updateInfo({ bank_name, bank_number, bank_holder, id_number, id_front, id_back })).then(
          unwrapResult,
        );
        message.success(`${t('update_success')}`);
      } catch (error) {
        message.error(`${t('update_fail')}`);
      }
    };
    message.config({
      maxCount: 1,
    });

    const onHandleUploadFront = {
      name: 'file',
      action: `${process.env.REACT_APP_API_URL}/upload`,
      onChange(info) {
        if (info.file.status === 'uploading') {
          message.loading('Đang tải lên');
        }
        if (info.file.status === 'done') {
          setUrlFront(info.file.response.url);
          form.setFieldsValue({
            id_front: info.file.response.url,
          });
          message.success(`Tải lên thành công`);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };

    const onHandleUploadBack = {
      name: 'file',
      action: `${process.env.REACT_APP_API_URL}/upload`,
      onChange(info) {
        if (info.file.status === 'uploading') {
          message.loading('Đang tải lên');
        }
        if (info.file.status === 'done') {
          setUrlBack(info.file.response.url);
          form.setFieldsValue({
            id_back: info.file.response.url,
          });
          message.success(`Tải lên thành công`);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };

    return (
      <div className='flex flex-col w-80'>
        <span className='flex bg-[#02142b] px-16 h-10 font-bold text-xl text-white items-center '>
          {t('account_info')}
        </span>
        <div className='mx-2 mt-2'>
          <Form form={form} onFinish={onFinish}>
            <span className='text-white text-base'>{t('bank_name')}</span>
            <Form.Item name='bank_name'>
              <Select disabled={user.bank_name} placeholder={t('bank_name')} showSearch>
                <Select.Option value='Vietcombank'>Vietcom Bank</Select.Option>
                <Select.Option value='Exim Bank'>Exim Bank</Select.Option>
                <Select.Option value='Vietin Bank'>Vietin Bank</Select.Option>
                <Select.Option value='SacomBank'>SacomBank</Select.Option>
                <Select.Option value='Vietnam Prosperity Joint-Stock Commercial Bank'>
                  Vietnam Prosperity Joint-Stock Commercial Bank
                </Select.Option>
                <Select.Option value='Techcombank'>Techcombank</Select.Option>
                <Select.Option value='BIDV Bank'>BIDV Bank</Select.Option>
                <Select.Option value='MB Bank'>MB Bank</Select.Option>
                <Select.Option value='KienLong Bank'>KienLong Bank</Select.Option>
                <Select.Option value='HD Bank'>HD Bank</Select.Option>
                <Select.Option value='SHB Bank'>SHB Bank</Select.Option>
                <Select.Option value='SCB Bank'>SCB Bank</Select.Option>
                <Select.Option value='ACB Bank'>ACB Bank</Select.Option>
                <Select.Option value='AB Bank'>AB Bank</Select.Option>
                <Select.Option value='Agri Bank'>Agri Bank</Select.Option>
                <Select.Option value='Bac A Bank'>Bac A Bank</Select.Option>
                <Select.Option value='BaoViet Bank'>BaoViet Bank</Select.Option>
                <Select.Option value='DONGA Bank'>DONGA Bank</Select.Option>
                <Select.Option value='GP Bank'>GP Bank</Select.Option>
                <Select.Option value='INDOVINA Bank'>INDOVINA Bank</Select.Option>
                <Select.Option value='LienViet Post Bank'>LienViet Post Bank</Select.Option>
                <Select.Option value='Maritime'>Maritime</Select.Option>
                <Select.Option value='Nam A Bank'>Nam A Bank</Select.Option>
                <Select.Option value='Navi Bank'>Navi Bank</Select.Option>
                <Select.Option value='NCB'>NCB</Select.Option>
                <Select.Option value='OCB (PHUONG DONG)'>OCB (PHUONG DONG)</Select.Option>
                <Select.Option value='PG Bank'>PG Bank</Select.Option>
                <Select.Option value='PVCOM Bank'>PVCOM Bank</Select.Option>
                <Select.Option value='SaiGon Bank'>SaiGon Bank</Select.Option>
                <Select.Option value='SaA Bank'>SaA Bank</Select.Option>
                <Select.Option value='ShinHan Bank'>ShinHan Bank</Select.Option>
                <Select.Option value='Tien Phong Bank'>Tien Phong Bank</Select.Option>
                <Select.Option value='United Overseas Bank'>United Overseas Bank</Select.Option>
                <Select.Option value='VIB Bank'>VIB Bank</Select.Option>
                <Select.Option value='VietABank'>VietABank</Select.Option>
                <Select.Option value='VPBANK'>VPBANK</Select.Option>
              </Select>
            </Form.Item>

            <span className='text-white text-base'>{t('bank_num')}</span>
            <Form.Item name='bank_number'>
              <Input disabled={user.bank_number} type='number' placeholder={t('bank_num')} />
            </Form.Item>
            <span className='text-white text-base'>{t('bank_holder')}</span>

            <Form.Item name='bank_holder'>
              <Input disabled={user.bank_holder} placeholder={t('bank_holder')} />
            </Form.Item>
            <span className='text-white text-base'>{t('id_num')}</span>

            <Form.Item name='id_number'>
              <Input disabled={user.id_number} type='number' placeholder={t('id_num')} />
            </Form.Item>

            {!user.isVerified && (
              <>
                <p className='text-white text-base'>{t('id_front')}</p>
                <Form.Item name='id_front'>
                  <Upload {...onHandleUploadFront} accept='.png, .jpg, .jpeg, .PNG, .JPG, .JPEG' showUploadList={false}>
                    <Button disabled={user.id_front} icon={<UploadOutlined />}>
                      {t('upload_image')}
                    </Button>
                  </Upload>
                </Form.Item>
                {urlFront && <Image className='rounded-xl' src={urlFront} width={100} height={100} />}

                <p className='text-white text-base'>{t('id_back')}</p>
                <Form.Item name='id_back'>
                  <Upload {...onHandleUploadBack} accept='.png, .jpg, .jpeg, .PNG, .JPG, .JPEG' showUploadList={false}>
                    <Button disabled={user.id_back} icon={<UploadOutlined />}>
                      {t('upload_image')}
                    </Button>
                  </Upload>
                </Form.Item>
                {urlBack && <Image className='rounded-xl' src={urlBack} width={100} height={100} />}
              </>
            )}

            <Form.Item>
              <Button disabled={user.isVerified} className='w-full' type='primary' htmlType='submit'>
                {t('update')}
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className='bg-[rgba(255,255,255,.2)] mx-2 my-2 rounded-lg mb-6'>
          <div className='flex mx-2 flex-col'>
            <span className=' px-6 w-72 py-4 '>
              <span className='font-bold text-white'>{t('note')}:</span>
              <p className='text-white'>{t('note1')}</p>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderThongTinCaNhan = () => {
    const onFinish = async (values) => {
      const { fullname, phone } = values;
      try {
        await dispatch(updateInfo({ fullname, phone })).then(unwrapResult);
        message.success(t('update_success'));
      } catch (error) {
        message.error(`${t('update_fail')}: ${error}`);
      }
    };

    const onChangePassword = async (values) => {
      const { oldPassword, newPassword, confirmPassword } = values;

      if (newPassword !== confirmPassword) {
        message.error(`${t('password_not_match')}`);
        return;
      }

      try {
        await dispatch(changePassword({ oldPassword, newPassword })).then(unwrapResult);
        message.success(`${t('update_success')}`);
      } catch (error) {
        message.error(error);
      }
    };

    return (
      <div className='flex flex-col w-80'>
        <span className='flex bg-[#02142b] px-16 h-10 font-bold text-xl text-white items-center '>
          {t('user_info')}
        </span>
        <div className='mx-2'>
          <Form onFinish={onFinish}>
            <span className='text-white text-base'>{t('fullname')}</span>
            <Form.Item name='fullname'>
              <Input disabled={user.fullname} placeholder={t('fullname')} />
            </Form.Item>

            <span className='text-white text-base'>{t('phone')}</span>
            <Form.Item name='phone'>
              <Input disabled={user.phone} type='number' placeholder={t('phone')} />
            </Form.Item>
          </Form>
        </div>

        <div className='mx-2'>
          <Form onFinish={onChangePassword}>
            <span className='text-white text-base'>{t('change_password')}</span>
            <Form.Item name='oldPassword'>
              <Input placeholder={t('change_password')} />
            </Form.Item>

            <span className='text-white text-base'>{t('new_password')}</span>
            <Form.Item name='newPassword'>
              <Input placeholder={t('new_password')} />
            </Form.Item>

            <span className='text-white text-base'>{t('confirm_password')}</span>
            <Form.Item name='confirmPassword'>
              <Input placeholder={t('confirm_password')} />
            </Form.Item>

            <Form.Item>
              <Button className='w-full' type='primary' htmlType='submit'>
                {t('update')}
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className='bg-[rgba(255,255,255,.2)] mx-2 my-2 rounded-lg'>
          <div className='flex mx-2 flex-col'>
            <span className=' px-6 py-4 w-72'>
              <span className='font-bold text-white'>{t('note')}:</span>
              <p className='text-white'>{t('note2')}</p>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderNapTaiSan = () => {
    const onFinish = async (values) => {
      if (values.amount < sys.minDeposit) {
        message.error(`${t('so_tien_toi_thieu')} ${formatMoney(sys.minDeposit)}`);
        return;
      }
      const data = {
        username: user.username,
        amount: removeDotComma(depositAmount),
        type: TRANSACTION_TYPE.DEPOSIT,
      };

      try {
        await dispatch(userDeposit(data)).then(unwrapResult);
        message.success(`${t('deposit_success')}`);
        dispatch(getUserTransaction());
        formDeposit.resetFields();
      } catch (error) {
        message.error(error);
      }
    };

    const RenderAmountButton = () => {
      return (
        <div className='flex flex-wrap'>
          <Button
            onClick={() => {
              const amountText = '10000';
              setDepositAmount(amountText);
              formDeposit.setFieldValue('amount', Number(amountText));
            }}
            className='my-2 mx-1'
            type='default'
          >
            10K
          </Button>
          <Button
            onClick={() => {
              const amountText = '50000';
              setDepositAmount(amountText);
              formDeposit.setFieldValue('amount', Number(amountText));
            }}
            className='my-2 mx-1'
            type='default'
          >
            50K
          </Button>
          <Button
            onClick={() => {
              const amountText = '100000';
              setDepositAmount(amountText);
              formDeposit.setFieldValue('amount', Number(amountText));
            }}
            className='my-2 mx-1'
            type='default'
          >
            100K
          </Button>
          <Button
            onClick={() => {
              const amountText = '500000';
              setDepositAmount(amountText);
              formDeposit.setFieldValue('amount', Number(amountText));
            }}
            className='my-2 mx-1'
            type='default'
          >
            500K
          </Button>
          <Button
            onClick={() => {
              const amountText = '1000000';
              setDepositAmount(amountText);
              formDeposit.setFieldValue('amount', Number(amountText));
            }}
            className='my-2 mx-1'
            type='default'
          >
            1M
          </Button>
          <Button
            onClick={() => {
              const amountText = '5000000';
              setDepositAmount(amountText);
              formDeposit.setFieldValue('amount', Number(amountText));
            }}
            className='my-2 mx-1'
            type='default'
          >
            5M
          </Button>
        </div>
      );
    };

    return (
      <div className='flex flex-col w-80'>
        <span className='flex bg-[#02142b] justify-center h-10 font-bold text-xl text-white items-center '>
          {t('deposit')}
        </span>
        <div className='mx-2'>
          <div className='flex justify-between'>
            <span className='text-white'>{t('balance')}:</span>
            <span className='text-white'>{formatMoney(user.balance)}</span>
          </div>
          <Divider type='horizontal' className='bg-white' />
          <Form form={formDeposit} onFinish={onFinish}>
            <span className='text-white text-base'>{t('deposit_type')} : Internet Banking</span>
            <Form.Item name='method'>
              <Select size='large' defaultValue='atm'>
                <Select.Option value='atm'>ATM</Select.Option>
              </Select>
            </Form.Item>
            <span className='text-white text-base'>{t('input_money')}</span>
            {/* <Form.Item name='amount'>
              <Input size='large' type='number' placeholder={t('input_money')} />
            </Form.Item> */}

            <CurrencyFormat
              className='w-full h-8 px-2 rounded-sm mb-4'
              value={depositAmount}
              thousandSeparator={true}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                setDepositAmount(formattedValue);
                formDeposit.setFieldValue('amount', Number(value));
              }}
            />

            {/* <div>
              <RenderAmountButton />
            </div> */}

            <Form.Item>
              <Button className='w-full' type='primary' htmlType='submit'>
                {t('confirm')}
              </Button>
            </Form.Item>
          </Form>
        </div>

        <div className='flex flex-col'>
          <span className='px-2 w-72'>
            <span className='font-bold text-white'>{t('payment_method')}:</span>
            <p className='text-white'>
              {t('deposit1')}
              <span className='flex'>
                {t('deposit2')} : <span className='text-red-600 ml-2'>{user.username}</span>
              </span>
              {t('deposit3')}
            </p>
          </span>
        </div>

        {bankData.length > 0 &&
          bankData.map((item) => {
            return item.type === 'BANK' ? (
              <div className='bg-[rgba(255,255,255,.2)] mx-2 my-2 rounded-lg'>
                <span className='flex flex-col px-2 py-3 w-72'>
                  <span className='font-semibold my-1 text-white text-lg '>
                    {t('bank_name')}: {item?.bank_name}
                  </span>
                  <span className='font-semibold my-1 text-white text-lg '>
                    {t('bank_num')}: {item?.bank_number}
                  </span>
                  <span className='font-semibold my-1 text-white text-lg '>
                    {t('bank_holder')}: {item?.bank_holder}
                  </span>
                </span>
              </div>
            ) : item.type === 'USDT' ? (
              <div className='bg-[rgba(255,255,255,.2)] mx-2 my-2 rounded-lg'>
                <span className='flex flex-col px-2 py-3 w-72'>
                  <span className='font-semibold my-1 text-white text-lg '>USDT (BEP20)</span>
                  <span className='font-semibold my-1 text-white text-lg '>
                    {t('usdt_address')}: {item?.USDT_address}
                  </span>
                  <div>
                    <span className='font-semibold text-white text-lg'>{t('scan_qr')} </span>
                    <QRCode size={140} bgColor='#fff' value={item?.USDT_address} />
                  </div>
                </span>
              </div>
            ) : null;
          })}

        <div className='flex flex-col bg-[rgba(255,255,255,.2)] mx-2 rounded-lg mt-6 py-4 mb-4'>
          <span className='px-2 w-72'>
            <span className='font-semibold text-sm text-white'>{t('note')}:</span>
            <span className='flex flex-col'>
              <span className='text-white text-sm'>{t('deposit_note1')}</span>
              <span className='text-white text-sm'>{t('deposit_note2')}</span>
              <span className='text-white text-sm'>{t('deposit_note3')}</span>
              <span className='text-white text-sm'>{t('deposit_note4')}</span>
              <span className='text-white text-sm'>{t('deposit_note5')}</span>
              <span className='text-white text-sm'>{t('deposit_note6')}</span>
            </span>
          </span>
        </div>
      </div>
    );
  };

  const renderRutTaiSan = () => {
    const onFinish = async (values) => {
      const data = {
        username: user.username,
        amount: removeDotComma(withdrawAmount),
        type: TRANSACTION_TYPE.WITHDRAW,
      };

      // if (removeDotComma(withdrawAmount) < sys.minWithdraw) {
      //   message.error(`${t('min_withdraw')} ${formatMoney(sys.minWithdraw)}`);
      //   return;
      // }

      try {
        await dispatch(userWithdraw(data)).then(unwrapResult);
        dispatch(getUserTransaction());
        dispatch(whoami());
        message.success(`${t('withdraw_success')}`);
        formWithdraw.resetFields();
      } catch (error) {
        message.error(error);
      }
    };

    const RenderAmountButton = () => {
      return (
        <div className='flex flex-wrap'>
          <Button
            onClick={() => {
              const amountText = '10000';
              setWithdrawAmount(amountText);
              formWithdraw.setFieldValue('amount', Number(amountText));
            }}
            className='my-2 mx-1'
            type='default'
          >
            10K
          </Button>
          <Button
            onClick={() => {
              const amountText = '50000';
              setWithdrawAmount(amountText);
              formWithdraw.setFieldValue('amount', Number(amountText));
            }}
            className='my-2 mx-1'
            type='default'
          >
            50K
          </Button>
          <Button
            onClick={() => {
              const amountText = '100000';
              setWithdrawAmount(amountText);
              formWithdraw.setFieldValue('amount', Number(amountText));
            }}
            className='my-2 mx-1'
            type='default'
          >
            100K
          </Button>
          <Button
            onClick={() => {
              const amountText = '500000';
              setWithdrawAmount(amountText);
              formWithdraw.setFieldValue('amount', Number(amountText));
            }}
            className='my-2 mx-1'
            type='default'
          >
            500K
          </Button>
          <Button
            onClick={() => {
              const amountText = '1000000';
              setWithdrawAmount(amountText);
              formWithdraw.setFieldValue('amount', Number(amountText));
            }}
            className='my-2 mx-1'
            type='default'
          >
            1M
          </Button>
          <Button
            onClick={() => {
              const amountText = '5000000';
              setWithdrawAmount(amountText);
              formWithdraw.setFieldValue('amount', Number(amountText));
            }}
            className='my-2 mx-1'
            type='default'
          >
            5M
          </Button>
        </div>
      );
    };

    return (
      <div className='flex flex-col w-80'>
        <span className='flex bg-[#02142b] h-10 font-bold text-xl justify-center text-white items-center '>
          {t('withdraw')}
        </span>
        <div className='mx-2'>
          <div className='flex justify-between'>
            <span className='text-white'>{t('balance')}:</span>
            <span className='text-white'>{formatMoney(user.balance)}</span>
          </div>
          <Divider type='horizontal' className='bg-white' />
          <Form form={formWithdraw} onFinish={onFinish}>
            <span className='text-white text-base'>{t('method_withdraw')} : Internet Banking</span>

            <Form.Item name='method'>
              <Select size='large' defaultValue='atm'>
                <Select.Option value='atm'>ATM</Select.Option>
              </Select>
            </Form.Item>

            {/* <p className='text-white'>
              {t('min_withdraw')}: {formatMoney(sys.minWithdraw)}
            </p>
            <p className='text-white'>
              {t('fee_withdraw')} : {formatMoney(sys.feeWithdraw)}%
            </p> */}

            <span className='text-white text-base'>{t('input_money')}</span>
            <div className='flex'>
              <CurrencyFormat
                className='w-full h-8 px-2 rounded-sm mb-4'
                value={withdrawAmount}
                thousandSeparator={true}
                onValueChange={(values) => {
                  const { formattedValue, value } = values;
                  setWithdrawAmount(formattedValue);
                  formWithdraw.setFieldValue('amount', Number(value));
                }}
              />

              <Button
                onClick={() => {
                  if (user.balance > 0) {
                    formWithdraw.setFieldValue('amount', user.balance);
                    setWithdrawAmount(user.balance);
                  } else {
                    setWithdrawAmount(0);
                    formWithdraw.setFieldValue('amount', 0);
                  }
                }}
              >
                {t('max')}
              </Button>
            </div>

            {/* <RenderAmountButton /> */}

            <Form.Item>
              <Button className='w-full' type='primary' htmlType='submit'>
                {t('withdraw')}
              </Button>
            </Form.Item>
          </Form>
        </div>

        <div className='flex flex-col bg-[rgba(255,255,255,.2)] mx-2 rounded-lg mt-6 py-4'>
          <span className='px-2 w-72'>
            <span className='font-semibold text-sm text-white'>Ghi chú:</span>
            <span className='flex flex-col'>
              <span className='text-white text-sm'>{t('deposit_note1')}</span>
              <span className='text-white text-sm'>{t('deposit_note2')}</span>
              <span className='text-white text-sm'>{t('deposit_note3')} </span>
              <span className='text-white text-sm'>{t('deposit_note4')}</span>
              <span className='text-white text-sm'>{t('deposit_note5')}</span>
              <span className='text-white text-sm'>{t('deposit_note6')} </span>
            </span>
          </span>
        </div>

        {/* <div className='flex flex-col bg-[rgba(255,255,255,.2)] mx-2 rounded-lg mt-6 py-4  mb-6'>
          <span className='px-2 w-72 '>
            <span className='font-semibold text-sm text-white'>Ghi chú:</span>
            <span className='flex flex-col'>
              <span className='text-white text-sm'>
                1. Sau khi thành viên điền số tài khoản lần đầu tiên sẽ bị ràng buộc vĩnh viễn mà không có bất kỳ sự
                thay đổi nào.
              </span>

              <span className='text-white text-sm'>
                2. Để ngăn những người quan tâm sử dụng trang web này như một công cụ gian lận: Vui lòng xác nhận thông
                tin tài khoản của bạn trước khi đăng ký.
              </span>

              <span className='text-white text-sm'>
                3. Nếu bạn cần thay đổi thông tin vì lý do cần thiết, vui lòng liên hệ với nhân viên dịch vụ khách hàng.
              </span>

              <span className='text-white text-sm'>
                4. Nếu bạn sử dụng nền tảng này để thực hiện bất kỳ hành vi gian lận rửa tiền nào, công ty có quyền xem
                xét tài khoản thành viên hoặc chấm dứt vĩnh viễn các dịch vụ của thành viên mà không cần thông báo
                trước.
              </span>
            </span>
          </span>
        </div> */}
      </div>
    );
  };

  const renderLichSuChoi = () => {
    const filterBetTransaction = transactionData.filter((item) => item.type === TRANSACTION_TYPE.BET);
    const columns = [
      {
        title: <span>{t('time')}</span>,
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text) => <span className='text-xs'>{moment(text).format('DD/MM/YYYY HH:mm:ss')}</span>,
      },
      {
        title: <span>{t('amount_money')}</span>,
        dataIndex: 'betAmount',
        key: 'betAmount',
        render: (text) => <>{formatMoney(text)}</>,
      },
      {
        title: <span>{t('type')}</span>,
        dataIndex: 'betType',
        key: 'betType',
        render: (text) =>
          text === 'BUY' ? (
            <span className='text-green-400'>{t('up')}</span>
          ) : (
            <span className='text-red-400'>{t('down')}</span>
          ),
      },
      {
        title: <span>{t('result')}</span>,
        dataIndex: 'betResult',
        key: 'betResult',
        render: (item) =>
          item === 'WIN' ? (
            <span className='text-green-400'>{t('lai')}</span>
          ) : (
            <span className='text-red-400'>{t('lo')}</span>
          ),
      },
    ];

    return (
      <div>
        <span className='flex bg-[#02142b] w-80 px-16 h-10 font-bold text-xl justify-center text-white items-center '>
          {t('history_transaction')}
        </span>

        <Table
          rowClassName={(record, index) =>
            index % 2 === 0 ? 'bg-[#02142b] text-white hover:text-black' : 'bg-[#02142b] text-white hover:text-black'
          }
          columns={columns}
          dataSource={filterBetTransaction}
        />
      </div>
    );
  };

  const renderLichSuNapRut = () => {
    const filterTransaction = transactionData.filter(
      (item) => item.type === TRANSACTION_TYPE.DEPOSIT || item.type === TRANSACTION_TYPE.WITHDRAW,
    );

    const columns = [
      {
        title: <span>{t('time')}</span>,
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text) => <span className='text-xs'>{moment(text).format('DD/MM/YYYY HH:mm:ss')}</span>,
      },
      {
        title: <span>{t('type')}</span>,
        dataIndex: 'type',
        key: 'type',
        render: (text) =>
          text === TRANSACTION_TYPE.DEPOSIT ? (
            <span className='text-green-400'>
              <span>{t('deposit')}</span>
            </span>
          ) : (
            <span className='text-red-400'>
              <span>{t('withdraw')}</span>
            </span>
          ),
      },
      {
        title: <span>{t('amount_money')}</span>,
        dataIndex: 'amount',
        key: 'amount',
        render: (text) => `${formatMoney(text)}`,
      },
      {
        title: <span>{t('status')}</span>,
        dataIndex: 'status',
        key: 'status',
        render: (item) => {
          if (item === TRANSACTION_STATUS.PENDING) {
            return <span className='text-yellow-400'>{t('pending')}</span>;
          }
          if (item === TRANSACTION_STATUS.SUCCESS) {
            return <span className='text-green-400'>{t('success')}</span>;
          }
          if (item === TRANSACTION_STATUS.CANCEL) {
            return <span className='text-red-400'>{t('reject')}</span>;
          }
        },
      },
    ];

    return (
      <div>
        <span className='flex bg-[#02142b] w-80 justify-center px-16 h-10 font-bold text-xl text-white items-center '>
          {t('history_dep_with')}
        </span>

        <Table
          rowClassName={(record, index) =>
            index % 2 === 0 ? 'bg-[#02142b] text-white hover:text-black' : 'bg-[#02142b] text-white hover:text-black'
          }
          columns={columns}
          dataSource={filterTransaction}
        />
      </div>
    );
  };

  return (
    <>
      <div className='flex'>
        <div className='menu w-24 h-screen-fit border border-[#011021] border-r-sky-400 '>
          <div
            onClick={() => setComponentSelected('renderNapTaiSan')}
            className={`flex flex-col h-16 justify-center items-center text-center menu_item cursor-pointer my-4`}
          >
            <WalletOutlined
              style={{ color: componentSelected === 'renderNapTaiSan' ? 'white' : '#fff' }}
              className='text-xl'
            />
            <span className={`text-xs ${componentSelected === 'renderNapTaiSan' ? 'text-blue-400' : 'text-white'}`}>
              {t('deposit')}
            </span>
          </div>

          <div
            onClick={() => setComponentSelected('renderRutTaiSan')}
            className={`flex flex-col h-16 justify-center items-center text-center menu_item cursor-pointer my-4`}
          >
            <DollarOutlined
              style={{ color: componentSelected === 'renderRutTaiSan' ? 'white' : '#fff' }}
              className='text-xl'
            />
            <span className={`text-xs ${componentSelected === 'renderRutTaiSan' ? 'text-blue-400' : 'text-white'}`}>
              {t('withdraw')}
            </span>
          </div>

          <div
            onClick={() => setComponentSelected('renderThongTinTaiKhoan')}
            className={`flex flex-col h-16 justify-center items-center text-center menu_item cursor-pointer my-4`}
          >
            <BankOutlined
              style={{ color: componentSelected === 'renderThongTinTaiKhoan' ? 'white' : '#fff' }}
              className='text-xl'
            />
            <span
              className={`text-xs ${componentSelected === 'renderThongTinTaiKhoan' ? 'text-blue-400' : 'text-white'}`}
            >
              {t('account_info')}
            </span>
          </div>

          <div
            onClick={() => setComponentSelected('renderThongTinCaNhan')}
            className={`flex flex-col h-16 justify-center items-center text-center menu_item cursor-pointer my-4`}
          >
            <UserOutlined
              style={{ color: componentSelected === 'renderThongTinCaNhan' ? 'white' : '#fff' }}
              className='text-xl'
            />
            <span
              className={`text-xs ${componentSelected === 'renderThongTinCaNhan' ? 'text-blue-400' : 'text-white'}`}
            >
              {t('user_info')}
            </span>
          </div>

          <div
            onClick={() => setComponentSelected('renderLichSuChoi')}
            className={`flex flex-col h-16 justify-center items-center text-center menu_item cursor-pointer my-4`}
          >
            <HistoryOutlined
              style={{ color: componentSelected === 'renderLichSuChoi' ? 'white' : '#fff' }}
              className='text-xl'
            />
            <span className={`text-xs ${componentSelected === 'renderLichSuChoi' ? 'text-blue-400' : 'text-white'}`}>
              {t('history_transaction')}
            </span>
          </div>

          <div
            onClick={() => setComponentSelected('renderLichSuNapRut')}
            className={`flex flex-col h-16 justify-center items-center text-center menu_item cursor-pointer my-4`}
          >
            <FileSearchOutlined
              style={{ color: componentSelected === 'renderLichSuNapRut' ? 'white' : '#fff' }}
              className='text-xl'
            />
            <span className={`text-xs ${componentSelected === 'renderLichSuNapRut' ? 'text-blue-400' : 'text-white'}`}>
              {t('history_dep_with')}
            </span>
          </div>
        </div>

        <div id='content'>
          {componentSelected === 'renderThongTinTaiKhoan'
            ? renderThongTinTaiKhoan()
            : componentSelected === 'renderThongTinCaNhan'
            ? renderThongTinCaNhan()
            : componentSelected === 'renderNapTaiSan'
            ? renderNapTaiSan()
            : componentSelected === 'renderRutTaiSan'
            ? renderRutTaiSan()
            : componentSelected === 'renderLichSuChoi'
            ? renderLichSuChoi()
            : componentSelected === 'renderLichSuNapRut'
            ? renderLichSuNapRut()
            : renderThongTinTaiKhoan()}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
